import React from 'react';

import Select, { BaseSelectProps } from '@common/react/components/base/BaseSelect/BaseSelect';
import BasePagination, { PaginationProps } from '@common/react/components/base/Pagination/BasePagination';

interface Props extends Omit<PaginationProps, 'renderSelect'> {
	selectProps?: Omit<BaseSelectProps, 'value' | 'option' | 'onChange'>;
}

const options = [10, 20, 30, 40].map((value) => ({
	label: value,
	value,
}));

const Pagination: React.FC<Props> = ({ selectProps, ...rest }) => {
	return <BasePagination
		{...rest}
		renderSelect={(current, pageSize) => <Select
			menuPlacement="top"
			{...selectProps}
			value={pageSize}
			options={options}
			onChange={(value: any) => {
				const newMaxPage = Math.ceil((rest.total || 0) / value.value);
				rest.onChange(current > newMaxPage ? newMaxPage : current, value.value);
			}}
		/>}
	/>;
};

export default Pagination;
