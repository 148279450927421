import React from 'react';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
import { useLocation } from 'react-router-dom';

import { useApplicationContext } from '@common/react/components/Core/Application/Application';
import BaseHostOptions from '@common/react/store/BaseHostOptions';

import '@app/scss/components/shareLinks.scss';

interface Props {
	size?: number;
	other?: React.ReactNode;
}

const ShareLinks: React.FC<Props> = ({ size = 50, other }) => {
	const location = useLocation();
	const { getHostOptions } = useApplicationContext();

	const hostOptions = getHostOptions<BaseHostOptions>();
	const url = `${hostOptions?.hostServer || 'https://www.beautybecomesyou.com/'}${location.pathname.slice(1)}`;

	return <div className="share-icons">
		<TwitterShareButton
			url={url}
		>
			<TwitterIcon size={size} round />
		</TwitterShareButton>
		<FacebookShareButton
			url={url}
		>
			<FacebookIcon size={size} round />
		</FacebookShareButton>
		<LinkedinShareButton
			url={url}
		>
			<LinkedinIcon size={size} round />
		</LinkedinShareButton>
		{other}
	</div>;
};

export default ShareLinks;
