import React from 'react';

import '@common/react/scss/components/base/pagination.scss';

export interface PaginationProps {
	current: number;
	pageSize: number;
	onChange: (page, pageSize) => void;
	total: number;
	className?: string;
	showSizeChanger?: boolean;
	renderSelect?: (page, pageSize) => React.ReactNode;
}

const BasePagination: React.FC<PaginationProps> = (props) => {
	const {
		current = 1,
		onChange,
		total,
		pageSize = 10,
		className = '',
		renderSelect,
		showSizeChanger = true,
	} = props;
	const maxPage = Math.ceil(total / pageSize);

	return <ul className={`pagination ${className}`}>
		<li
			key="prev"
			className={current === 1 ? 'disabled' : ''}
			onClick={() => current !== 1 && onChange(current - 1, pageSize)}
		>
			<a onClick={(e) => e.preventDefault()}>
				<i className="fa fa-angle-left" />
			</a>
		</li>
		{current > 3 && <li key={1} onClick={() => onChange(1, pageSize)} title="1">
			<a onClick={(e) => e.preventDefault()}>
				1
			</a>
		</li>}
		{current > 4 && <li key="dots">
			<a onClick={(e) => e.preventDefault()}>
				...
			</a>
		</li>}
		{Array.from({ length: 5 }).map((_, i) => i - 2)
			.map((key) => key + current >= 1 && key + current <= maxPage && <li
				key={key + current}
				title={`${key + current}`}
				className={key === 0 ? 'active' : ''}
				onClick={() => onChange(key + current, pageSize)}
			>
				<a onClick={(e) => e.preventDefault()}>
					{key + current}
				</a>
			</li>)}
		{current < maxPage - 3 && <li key="dots2">
			<a onClick={(e) => e.preventDefault()}>
				...
			</a>
		</li>}
		{current < maxPage - 2 && <li key={maxPage} onClick={() => onChange(maxPage, pageSize)} title={maxPage.toString()}>
			<a onClick={(e) => e.preventDefault()}>
				{maxPage}
			</a>
		</li>}
		<li
			className={current === maxPage ? 'disabled' : ''}
			key="next"
			onClick={() => onChange(current + 1, pageSize)}
		>
			<a onClick={(e) => e.preventDefault()}>
				<i className="fa fa-angle-right" />
			</a>
		</li>
		{showSizeChanger && renderSelect && <li key="options" className="options">
			{renderSelect?.(current, pageSize)}
		</li>}
	</ul>;
};

export default BasePagination;
