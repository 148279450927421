import React from 'react';

import loadable from '@loadable/component';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import { loadableDelay } from '@common/react/loadable/loadableSettings';

import { useReduxContext } from '@app/hooks/useReduxContext';

const GoogleMapReact = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "GoogleMapReact" */ 'google-map-react')),
	{ fallback: <Loading /> },
);

interface MapProps {
	address: string;
}

const defaultProps = {
	center: { lat: -34.397, lng: 150.644 },
	zoom: 12,
};

const codeAddress = (map, maps, address: string) => {
	const geocoder = new maps.Geocoder();
	let marker;
	geocoder.geocode({ address }, (results, status) => {
		if (status === 'OK') {
			map.setCenter(results[0].geometry.location);
			marker = new maps.Marker({
				map,
				position: results[0].geometry.location,
			});
		} else {
			console.log(`Geocode was not successful for the following reason: ${status}`);
		}
	});
	return marker;
};

const SimpleMap: React.FC<MapProps> = ({
	address,
}) => {
	const { companySettings: { googleApiKey } } = useReduxContext();

	const ref = React.useRef(null);
	const [visible, setVisible] = React.useState(false);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '15px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);

	const renderMarker = (map, maps) => {
		return codeAddress(map, maps, address);
	};

	return (
		<div className="map" ref={ref}>
			{visible ? <GoogleMapReact
				bootstrapURLKeys={{
					key: googleApiKey,
				}}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				onGoogleApiLoaded={({ map, maps }) => {
					renderMarker(map, maps);
				}}
			/> : <Loading />}
		</div>
	);
};

export default SimpleMap;
