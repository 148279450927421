import * as React from 'react';
import Slider from 'react-slick-adaptive-height-fixed';
import { useTranslation } from 'react-i18next';

import { ItemsProvider, SortingDirection } from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import LoadMoreButton from '@common/react/components/Core/ItemsProvider/LoadMoreButton';
import ServerPageProvider from '@common/react/components/UI/lang/SeverPageProviderWithLang/ServerpageProviderWithLang';

import { Special } from '@app/objects/Special';
import SpecialCard from '@app/components/Pages/Specials/SpecialCard';
import '@app/scss/pages/special.scss';
import SpecialModal from '@app/components/Pages/Specials/SpecialModal';
import { reviewsSliderSettings } from '@app/components/Utils';
import LocationSelect from '@app/components/UI/LocationSelect/LocationSelect';
import { useReduxContext } from '@app/hooks/useReduxContext';

interface Props {
	items: any;
	asSlider?: boolean;
}

export const SpecialsInner: React.FC<Props> = ({ items, asSlider }) => {
	const [item, setItem] = React.useState<Special>();

	return <>
		<SpecialModal item={item} onClose={() => setItem(undefined)} />
		<div className="container specials-container">
			<div className="special-list__wrapper">
				<div className="special-list">
					{items && (asSlider ? <>
						<Slider
							{...reviewsSliderSettings}
							autoplay={false}
							infinite={false}
							slidesToShow={items.list.length >= 3 ? 3 : items.list.length}
							responsive={[
								{
									breakpoint: 768,
									settings: {
										slidesToShow: 2,
										rows: 1,
									},
								},
								{
									breakpoint: 600,
									settings: {
										slidesToShow: 1,
									},
								},
							]}
						>
							{items.list.map((special: Special) =>
								<SpecialCard openModal={setItem} item={special} key={special.id} />)}
						</Slider>
					</>
						: items.list.map((special: Special) => {
							return <SpecialCard openModal={setItem} item={special} key={special.id} />;
						}))}
				</div>
				{asSlider ? null : <div className="text-center load-more-btn mb10">
					<LoadMoreButton silent pageSize={12}>
						Load More
					</LoadMoreButton>
				</div>}
			</div>
		</div>
	</>;
};

const Specials: React.FC = () => {
	const {
		offices,
	} = useReduxContext();
	const { t } = useTranslation();
	return <>
		<ServerPageProvider
			path="specials"
			defaultTitle="Specials"
			inner={(page) => (
				<ItemsProvider<Special>
					type="special"
					items={page?.items?.list}
					loadRequest="specialsList"
					filters={{
						column: [
							{ caption: 'name', direction: SortingDirection.Ascending },
							{ caption: 'price', direction: SortingDirection.Default }],
					}}
					pagination={{ total: page?.items?.count }}
					withHandleUrl
				>
					{({ state: { items, filters }, actions: { handleChange } }) => <>
						<div className="container specials-container">
							<div className="special-list">
								<LocationSelect
									placeholder={t('site.Locations')}
									items={offices}
									value={filters.locationId > 0 ? filters.locationId : undefined}
									onChange={(value) => {
										handleChange({ locationId: +value?.value });
									}}
									isClearable
								/>
							</div>
						</div>
						<SpecialsInner items={{ list: items }} />
						<style>
							{'.app-container {background: #eeeff3;}'}
						</style>
					</>}
				</ItemsProvider>
			)}
		/>
	</>;
};

export default Specials;
