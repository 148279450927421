import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { ItemProviderContext, useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

export interface ItemEditorWrapperProps<T extends WithDeleted> {
	/**
	 * function providing ItemProvider context and two callbacks
	 * @param context - ItemProviderContext
	 * @param handlerBack - callback for 'Back' button
	 * @param afterSubmit - after submit callBack
	 */
	render: (context: ItemProviderContext<T>, handlerBack: () => void, afterSubmit: (T) => void) => React.ReactNode;
	/**
	 * default back path for handleBack
	 */
	backPath: string;
	/**
	 * how handle page in url. By default false
	 * `pathname/${page}` or `${pathname}?page=${page}`
	 */
	pageInSearch?: boolean;
	/**
	 * custom afterSubmit
	 * @param item - item saved item
	 * @param setItem - setItem from ItemProvider context
	 * @param navigate - navigate from react-router-dom
	 * @param pageInSearch - same as ItemEditorWrapper pageInSearch props
	 */
	afterSubmit?: (item: T, setItem: React.Dispatch<React.SetStateAction<T>>, navigate, pageInSearch?: boolean) => void;
	/**
	 * custom handlerBack
	 * @param navigate - navigate from react-router-dom
	 * @param backPath - same as ItemEditorWrapper backPath props
	 */
	handlerBack?: (navigate, backPath: string) => void;
	/**
	 * div wrapper class name, by default "container"
	 */
	className?: string;
}

const defaultHandleBack = (navigate, backPath: string, location) => {
	if (location.state?.prevPath) {
		navigate(location.state.prevPath);
	} else {
		navigate(backPath);
	}
};

const defaultAfterSubmit = (item, setItem, navigate, pageInSearch, location) => {
	setItem({ ...item });
	if (pageInSearch) {
		const filters = {
			page: pageInSearch ? item.id : undefined,
		};

		handleUrl(filters, location, navigate, item.id, '', pageInSearch);
	} else {
		navigate({
			...location,
			pathname: location.pathname.replace('/-1', `/${item.id}`),
		}, { replace: true });
	}
};

/**
 * ItemEditorWrapper component.
 *
 * usage examples:
 *  - <ItemEditorWrapper backPath="/item-list" render={(context, handlerBack, afterSubmit) => React.ReactNode} />
 *
 * @typeParam T - T Any {WithDeleted}
 * @param props - ItemEditorWrapperProps
 * @type {React.FC<ItemEditorWrapperProps>}
 * @returns React.ReactElement
 */
export const ItemEditorWrapper: <T extends WithDeleted>(p: ItemEditorWrapperProps<T>) => React.ReactElement<T> = <T extends WithDeleted, >({
	render,
	backPath,
	pageInSearch = false,
	handlerBack: handleBackProps = defaultHandleBack,
	afterSubmit: afterSubmitProps = defaultAfterSubmit,
	className = 'container',
}) => {
	const context = useItemProviderContext<T>();

	const {
		actions: { setItem },
		state: { item, itemLoading },
	} = context;

	const location = useLocation();
	const navigate = useNavigate();

	const handlerBack = React.useMemo(() => {
		return () => {
			handleBackProps(navigate, backPath, location);
		};
	}, [location, backPath]);

	const afterSubmit = React.useMemo(() => {
		return (item: T) => {
			afterSubmitProps(item, setItem, navigate, pageInSearch, location);
		};
	}, [setItem, location, pageInSearch]);

	return !item || itemLoading
		? <Loader />
		: <div className={className}>{render(context, handlerBack, afterSubmit)}</div>;
};
