import * as React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import { replaceExceptNumber } from '@common/typescript/Utils';

import { solveWorkingHours } from '@commonTuna/react/utils/workingHours/workingHours';
import { Location } from '@commonTuna/react/objects/BaseLocation';

import { getGoogleMapLink } from '@app/components/Utils';
import Map from '@app/components/UI/Map/Map';
import SocialIcons from '@app/components/UI/SocialIcons/SocialIcons';
import { useReduxContext } from '@app/hooks/useReduxContext';

interface Props {
	office: Location;
	titleAsH2?: boolean;
	showWorkingHours?: boolean;
}

const showTime = (time) => {
	const [h, m] = time.split(':');
	return `${+h > 12 ? `${+h - 12}` : h}:${m} ${+h > 12 ? 'p' : 'a'}.m.`;
};

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const daysEs = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const OfficeInfoBlock: React.FC<Props> = ({ office, titleAsH2, showWorkingHours = true }) => {
	const { i18n, t } = useTranslation();
	const arr = React.useMemo(() =>
		[office.addressEn, office.city, office.state, office.zip].filter((str) => str), []);

	const onClick = (e) => {
		const el = e?.target;
		if (el?.closest('.gm-control-active') || el?.classList.contains('gm-control-active')
			|| el.tagName?.toLowerCase() === 'a') {
			e.preventDefault();
		}
	};

	const path = i18n.language === 'es' ? `es/${office.pathEn}` : office.pathEn;
	const name = i18n.language === 'es' ? office.nameEs : office.nameEn;
	const title = path ? <Link to={`/clinic/${path}`}>{name}</Link> : name;
	const address = arr.filter((e) => e).join(',');
	const link = getGoogleMapLink(address);

	const workingHours = React.useMemo(() => {
		if (!showWorkingHours) return [];
		return solveWorkingHours(office.workingHours);
	}, [office.workingHours]);

	return <div className="office-info-block">
		<div>
			{titleAsH2 ? <h2 className="office-info-block__title">{title}</h2> : <h3 className="office-info-block__title">{title}</h3>}
			<div className="office-info-block__address">
				<a
					className=""
					href={link}
					target="_blank"
					rel="noreferrer"
				>
					{arr.map((str, i) => <span key={i}>
						{str}
						{i + 1 !== arr.length ? ',' : ''}
						{' '}
					</span>)}
				</a>
				<br />
				<strong>
					{t('forms.phone')}
					:
				</strong>
				{' '}
				<a href={`tel:${replaceExceptNumber(phoneFormat(office.phone))}`}>{phoneFormat(office.phone)}</a>
				<br />
				<strong>Fax:</strong>
				{' '}
				<a href={`tel:${replaceExceptNumber(phoneFormat(office.fax))}`}>{phoneFormat(office.fax)}</a>
				{showWorkingHours && !!workingHours.length ? workingHours.map((wh) => <React.Fragment
					key={wh.id}
				>
					<br />
					<strong>
						{i18n.language === 'es' ? wh.titleEs : wh.title}
						:
					</strong>
					{' '}
					{showTime(wh.startTime)}
					{' '}
					-
					{' '}
					{showTime(wh.endTime)}
				</React.Fragment>) : null}
				<SocialIcons locationId={office.id} className="office-info-block__icons" />
			</div>
		</div>
		{office.addressEn
			&& <a
				onClick={onClick}
				className="office-info-block__map"
				href={link}
				target="_blank"
				rel="noreferrer"
			>
				<Map address={address} />
			</a>
		}
	</div>;
};

const OfficesInfoList: React.FC<{titleAsH2?: boolean}> = ({ titleAsH2 }) => {
	const { offices } = useReduxContext();

	return (<>
		{offices.map((office) => <div className="col-md-6" key={office.id}>
			<OfficeInfoBlock office={office} titleAsH2={titleAsH2} />
		</div>)}
	</>
	);
};

export default OfficesInfoList;
