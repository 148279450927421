import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick-adaptive-height-fixed';

import 'slick-carousel/slick/slick.css';

import { dateFormat } from '@common/typescript/Utils';

import { Review } from '@commonTuna/react/objects/Review';

import { reviewsSliderSettings } from '@app/components/Utils';
import Rate from '@app/components/UI/Rate/Rate';
import '@app/scss/components/reviews.scss';
import SliderWithObserver from '@app/components/UI/SliderWithObserver/SliderWithObserver';

interface Props {
	reviews: Array<Review>;
	title?: React.ReactNode;
	className?: string;
	id?: string;
}

const Reviews: React.FC<Props> = ({ reviews, id, ...rest }) => {
	const { t } = useTranslation();
	const { title = t('site.Reviews from our clients'), className = '' } = rest;

	return <div className="scroll-to-content" id={id}>
		{reviews.length > 0 ? <div className={`service-page__reviews reviews slider-arrows service-page__block ${className} ${
			reviews.length === 1 ? 'reviews_one' : ''
		} ${reviews.length === 2 ? 'reviews_two' : ''}`}
		>
			<div className="service-page__title">
				<h3>{title}</h3>
			</div>
			<SliderWithObserver
				sliderProps={{
					...reviewsSliderSettings,
					...(reviews.length === 1 ? {
						slidesToShow: 1,
						responsive: [],
					} : reviews.length === 2 ? {
						slidesToShow: 2,
						responsive: [
							{
								breakpoint: 767,
								settings: {
									slidesToShow: 1,
								},
							},
						],
					} : {}),
				}}
			>
				{reviews.slice(0, 10).map((item: Review) => <div className="review-slide" key={item.id}>
					<div className="review-slide__head">
						<div className="review-slide__title">
							<div>{item.titleEn}</div>
							<Rate value={item.rate} />
						</div>
					</div>
					<div className="review-slide__body">
						<b>{item.textEn}</b>
						<div className="text-right" style={{ marginTop: 4 }}>
							-
							{' '}
							{item.author}
							{item.time > 0 && ` - ${dateFormat(item.time, { year: 'numeric', month: 'long', day: 'numeric' })}`}
						</div>
					</div>
				</div>)}
			</SliderWithObserver>
		</div> : null}
	</div>;
};

export default Reviews;
